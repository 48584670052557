
export default function Email() {
  return (

<iframe title="Newsletter subscription form" src="https://embeds.beehiiv.com/11b164d0-afd0-4f33-8b75-cce22f2e27eb" data-test-id="beehiiv-embed" width="100%" height="320" frameborder="0" scrolling="no" style={{borderRadius: '4px', border: '2px solid #e5e7eb', margin: '0', backgroundColor: 'transparent'}}></iframe>

  )
}



